import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: pnEnv.SENTRY_DSN,
  environment: pnEnv.RAILS_ENV,
  release: pnEnv.SENTRY_RELEASE,
  integrations: [new BrowserTracing()],
  tracesSampleRate: pnEnv.RAILS_ENV === 'development' ? 1.0 : 0.1,
});

// Allows for in-template error handling, e.g. simple Alpine components
window.Sentry = Sentry;
